<template>
  <div>
    <template v-if="essential">
      <GlPopup
        v-model="isOpenComputed"
        class="coin-select-button__wrapper"
      >
        <template #action>
          <template>
            <div
              class="coin-select-button__field"
              @click="isOpenComputed = !isOpenComputed"
            >
              <div class="flex align-center">
                <GlBlockchainIcon
                  :blockchain-icon="coin.key"
                  class="coin-icon"
                />
                <span>
                  {{ coin.label }}
                </span>
              </div>
              <gl-icon
                :class="`gl-user-dropdown__arrow-icon--${isOpenComputed ? 'opened' : 'closed'}`"
                :height="24"
                name="arrow-down"
                :width="24"
              />
            </div>
          </template>
        </template>
        <template #content>
          <template>
            <div class="dropdown">
              <div class="dropdown-search">
                <gl-input
                  v-model="searchQuery"
                  placeholder="Search"
                  style="height: 32px"
                  type="text"
                />
              </div>
              <div class="dropdown-list">
                <div
                  class="section"
                >
                  <span class="section-title">ADVANCED</span>
                  <div
                    v-for="coin in filteredCoins(currencyList)"
                    :key="coin.key"
                    class="coin-item"
                    @click="handleCoinSelect(coin)"
                  >
                    <gl-blockchain-icon
                      :blockchain-icon="coin.key"
                      class="coin-icon"
                    />
                    <span class="coin-name">{{ coin.blockchain }}</span>
                    <span class="coin-symbol">{{ coin.key }}</span>
                  </div>
                  <div
                    v-if="!filteredCoins(currencyList).length"
                    class="section-title__empty"
                  >
                    No options found
                  </div>
                </div>
                <div
                  class="section"
                >
                  <span class="section-title">ESSENTIAL</span>
                  <div
                    v-for="coin in filteredCoins(essentialCurrencyList)"
                    :key="coin.symbol"
                    class="coin-item"
                    @click="handleCoinSelect(coin)"
                  >
                    <gl-blockchain-icon
                      :blockchain-icon="coin.key"
                      class="coin-icon"
                    />
                    <span class="coin-name">{{ coin.blockchain }}</span>
                    <span class="coin-symbol">{{ coin.coin }}</span>
                  </div>
                  <div
                    v-if="!filteredCoins(essentialCurrencyList).length"
                    class="section-title__empty"
                  >
                    No options found
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </GlPopup>
    </template>
    <template v-else>
      <div
        v-if="label"
        class="gl-input__label mb-2"
      >
        {{ label }}
      </div>
      <vSelect
        v-if="hasMultyCoin"
        v-model="coin"
        class="coin-select-base mr-2 m-mr-0"
        :class="[
          { 'coin-select-base__dark': dark },
          { 'coin-select-base__small': small },
          { 'coin-select-base__slim': slim },
          { 'coin-select-base__bordered': bordered },
        ]"
        :clearable="false"
        :disabled="disabled"
        label="label"
        :options="currencyList.filter(c => !c.essential)"
        :searchable="false"
        @input="handleCoinSelect"
      />
    </template>
  </div>
</template>

<script>
// Components
import vSelect from 'vue-select'
// Utils
import config from '@/utils/appConfig'
// Vuex
import { mapMutations, mapState } from 'vuex'
// Static
import GlPopup from '@/components/gl-popup.vue'
import GlButton from '@/components/gl-button.vue'
import GlIcon from '@/components/gl-icon.vue'
import GlInput from '@/components/gl-input.vue'
import GlBlockchainIcon from '@/components/gl-blockchain-icon.vue'

export default {
  components: {
    GlBlockchainIcon,
    GlInput,
    GlIcon, GlButton,
    GlPopup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconHeight: {
      type: Number,
      default: 16,
    },
    iconWidth: {
      type: Number,
      default: 16,
    },
    iconAfter: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padder: {
      type: Boolean,
      default: false,
    },
    getOnlyData: {
      type: Boolean,
      default: false,
    },
    essential: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenComputed: false,
      isOpen: false,
      coin: {
        key: 'btc',
        blockchain: 'bitcoin',
        path: 'tx',
        label: 'BTC',
      },
      searchQuery: "",
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'currencyList', 'essentialCurrencyList']),
    hasMultyCoin() {
      return config.VUE_APP_COIN_TYPE
    },
  },
  watch: {
    coinType: {
      handler(coin) {
        this.SET_COIN_TYPE(coin)
        this.coin =
          this.currencyList.concat(this.essentialCurrencyList).find(item => item.key === coin) ||
          this.currencyList[0]
      },
      immediate: true,
    },
  },
  created() {
    // if (this.essential) {
    //   this.SET_COIN_CURRENCY_LIST([
    //     ...this.currencyList,
    //     ...essentialCurrencyList,
    //   ])
    // } else {
    //   this.SET_COIN_CURRENCY_LIST([...this.currencyList])
    // }
  },
  methods: {
    ...mapMutations({
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
      SET_COIN_CURRENCY_LIST: 'analytics/SET_COIN_CURRENCY_LIST',
    }),
    filteredCoins(coins) {
      return coins.filter((coin) =>
        coin.blockchain.toLowerCase().includes(this.searchQuery.toLowerCase()) || coin.key.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    handleCoinSelect(data) {
      this.isOpenComputed = false
      if (!this.getOnlyData) {
        this.SET_COIN_TYPE(data.key)
        this.SET_COIN_DATA(data)
      }
      this.$emit('change', data)
    },
  },
}
</script>

<style>
.coin-select-base .vs__dropdown-toggle {
  background: #fff;
  border: 1px solid #f5f5f5 !important;
  min-height: 38px;
  min-width: 150px !important;
}

.coin-select-base__dark .vs__dropdown-toggle {
  background: var(--cotton-grey-f-5);
  border: 1px solid var(--cotton-grey-f-5) !important;
  min-height: 38px;
  min-width: 150px !important;
}

.coin-select-base__small .vs__dropdown-toggle {
  min-height: 32px !important;
}

.coin-select-base__slim .vs__dropdown-toggle {
  min-width: 80px !important;
}

.coin-select-base__bordered .vs__dropdown-toggle {
  border: 1px solid var(--soft-blue) !important;
}

@media (max-width: 767px) {
  .coin-select-base__dark .vs__dropdown-toggle {
    min-height: 32px;
    padding: 0 !important;
  }
  .coin-select-base .vs__dropdown-toggle {
    min-height: 32px;
    padding: 0 !important;
  }
  .coin-select-base__slim .vs__selected-options {
    flex-wrap: unset;
  }
}

.coin-select-button__wrapper .gl-popup-content {
  padding: 0;
}

.coin-select-button__field {
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 3px;
  background: var(--cotton-grey-f-5);
  max-height: 100%;
}

.dropdown {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.dropdown-search {
  width: 100%;
  padding: 12px 16px 8px 16px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  font-size: 14px;
  box-sizing: border-box;
}

.dropdown-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.section-title, .section-title__empty {
  color: #98A3AA;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 8px 16px;
  display: block;
}

.section-title__empty {
  color: var(--space-cadet);
}

.coin-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  transition: background-color 0.3s;
  flex-wrap: wrap; /* Allow text to wrap to avoid overflow */
}

.coin-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.coin-icon {
  margin-right: 6px;
}

.coin-name {
  font-size: 14px;
  color: #091540;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.coin-symbol {
  color: #98A3AA;
  margin-left: auto;
  max-width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

@media (max-width: 600px) {
  .dropdown {
    max-width: 100%;
  }

  .coin-item {
    padding: 6px;
  }

  .coin-name {
    font-size: 13px;
  }

  .coin-symbol {
    font-size: 11px;
  }
}

.dropdown-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
</style>
