<template>
  <div
    v-click-outside="closeWindow"
    class="gl-popup-wrapper"
  >
    <div class="gl-popup-action">
      <slot name="action" />
    </div>

    <transition name="fade">
      <div
        v-if="innerValue"
        class="gl-popup-content"
      >
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
// Directives
import vClickOutside from 'v-click-outside'

export default {
  name: 'GlPopup',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get: function () {
        return this.value
      },
      set: function (e) {
        this.$emit('input', e)
      },
    },
  },
  methods: {
    closeWindow() {
      this.innerValue = false
    },
  },
}
</script>

<style>
.gl-popup-content {
  position: absolute;
  z-index: 2;
  padding: 16px;
  border-radius: 8px;
  background: var(--Icon-White, #fff);
  box-shadow: 0px 4px 12px 0px rgba(9, 21, 64, 0.1);
  overflow: auto;
}
</style>
